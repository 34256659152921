const selectors = {
    waardecheckInfo: 'div.waardecheck-info[data-waardecheck-value]',
    isWaardecheckPrice: 'span[data-js-waardecheck="true"]'
};

function calculateWaardechequePrices() {
    if (!$(selectors.waardecheckInfo)) return;
    var waardecheckValue = parseFloat($(selectors.waardecheckInfo).attr('data-waardecheck-value'));
    $(selectors.isWaardecheckPrice).each(function () {
        if($(this).hasClass('range__details--waardecheck')) return;
        var prices = $(this).find('span[content]');
        var price = parseFloat($(prices[prices.length - 1]).attr('content'))

        if (price >= waardecheckValue) {
            $(this).addClass('range__details--waardecheck');

            var newPrice = (price - waardecheckValue).toFixed(2);

            $(this).find('.waardecheck').removeClass('hidden');
            $(this).find('.waardecheck__msg').removeClass('hidden');
            $(this).find('.waardecheck').find('.value').attr('content', newPrice);
            $(this).find('.waardecheck').find('.value').text(newPrice.replace('.', ','));
        }
    });
}



$(document).ready(function () {
    calculateWaardechequePrices();
    $('body').on("calculateWaardechequePrices", function (event) {
        calculateWaardechequePrices();
    });
});